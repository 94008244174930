import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'hu',
    resources: {
      hu: {
        translation: {
          nav: {
            store: 'Áruház',
            licenses: 'Licenszek',
            support: 'Támogatás',
            login: 'Bejelentkezés',
            register: 'Regisztráció'
          },
          home: {
            title: 'Prémium WordPress Plugin Áruház',
            subtitle: 'Bővítsd weboldaladat prémium minőségű pluginekkel',
            cta: {
              store: 'Plugin Áruház',
              contact: 'Kapcsolat'
            }
          }
          // ... további fordítások
        }
      },
      en: {
        translation: {
          nav: {
            store: 'Store',
            licenses: 'Licenses',
            support: 'Support',
            login: 'Login',
            register: 'Register'
          },
          home: {
            title: 'Premium WordPress Plugin Store',
            subtitle: 'Enhance your website with premium quality plugins',
            cta: {
              store: 'Plugin Store',
              contact: 'Contact'
            }
          }
          // ... további fordítások
        }
      }
    }
  });

export default i18n; 