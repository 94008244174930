import React from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';

const Documentation: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Dokumentáció
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Részletes útmutatók és dokumentáció a WP Market pluginek használatához.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Kezdő lépések */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="text-blue-600 mb-4">
              <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold mb-3">Kezdő lépések</h2>
            <ul className="space-y-2 text-gray-600">
              <li><Link to="/docs/getting-started" className="hover:text-blue-600">• Regisztráció és bejelentkezés</Link></li>
              <li><Link to="/docs/getting-started" className="hover:text-blue-600">• Plugin vásárlás</Link></li>
              <li><Link to="/docs/getting-started" className="hover:text-blue-600">• Licensz aktiválás</Link></li>
            </ul>
          </div>

          {/* Plugin telepítés */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="text-blue-600 mb-4">
              <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold mb-3">Plugin telepítés</h2>
            <ul className="space-y-2 text-gray-600">
              <li><Link to="/docs/installation" className="hover:text-blue-600">• WordPress telepítés</Link></li>
              <li><Link to="/docs/installation" className="hover:text-blue-600">• Automatikus frissítések</Link></li>
              <li><Link to="/docs/installation" className="hover:text-blue-600">• Hibaelhárítás</Link></li>
            </ul>
          </div>

          {/* API Dokumentáció */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="text-blue-600 mb-4">
              <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold mb-3">API Dokumentáció</h2>
            <ul className="space-y-2 text-gray-600">
              <li><Link to="/docs/api" className="hover:text-blue-600">• REST API endpoints</Link></li>
              <li><Link to="/docs/api" className="hover:text-blue-600">• Licensz ellenőrzés</Link></li>
              <li><Link to="/docs/api" className="hover:text-blue-600">• Webhook integráció</Link></li>
            </ul>
          </div>
        </div>

        {/* Keresés és navigáció */}
        <div className="mt-12 bg-white rounded-lg shadow-sm p-8">
          <div className="max-w-3xl mx-auto">
            <div className="relative">
              <input
                type="text"
                placeholder="Keresés a dokumentációban..."
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <div className="absolute right-3 top-3 text-gray-400">
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentation; 