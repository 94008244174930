import './i18n/config';
import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import { CssBaseline } from '@mui/material';
import UserManager from './components/UserManager';
import PluginManager from './components/PluginManager';
import LicenseManager from './components/LicenseManager';
import Store from './components/Store';
import MyLicenses from './components/MyLicenses';
import Purchases from './components/Purchases';
import LandingPage from './components/LandingPage';
import Support from './components/Support';
import Documentation from './components/Documentation';

const App: React.FC = () => {
  return (
    <Router>
      <CssBaseline />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <UserManager />
              </PrivateRoute>
            }
          />
          <Route
            path="/plugins"
            element={
              <PrivateRoute>
                <PluginManager />
              </PrivateRoute>
            }
          />
          <Route
            path="/licenses"
            element={
              <PrivateRoute>
                <LicenseManager />
              </PrivateRoute>
            }
          />
          <Route path="/store" element={<Store />} />
          <Route path="/my-licenses" element={<MyLicenses />} />
          <Route path="/purchases" element={<Purchases />} />
          <Route path="/support" element={<Support />} />
          <Route path="/docs" element={<Documentation />} />
          <Route path="/docs/:section" element={<Documentation />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
