import React from 'react';
import Navbar from './Navbar';

const Support: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Támogatás
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Segítünk megoldani a problémádat. Válaszd ki az alábbi lehetőségek közül a számodra megfelelőt.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Dokumentáció */}
          <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
            <div className="text-blue-600 mb-4">
              <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-2">Dokumentáció</h3>
            <p className="text-gray-600 mb-4">
              Részletes dokumentáció és útmutatók a pluginek használatához.
            </p>
            <a href="https://wpmarket.app/docs" className="text-blue-600 hover:text-blue-700 font-medium">
              Dokumentáció megnyitása →
            </a>
          </div>

          {/* Email támogatás */}
          <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
            <div className="text-blue-600 mb-4">
              <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-2">Email Támogatás</h3>
            <p className="text-gray-600 mb-4">
              Technikai kérdések esetén írj nekünk emailt.
            </p>
            <a href="mailto:support@wpmarket.app" className="text-blue-600 hover:text-blue-700 font-medium">
              Email küldése →
            </a>
          </div>

          {/* GYIK */}
          <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
            <div className="text-blue-600 mb-4">
              <svg className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 className="text-xl font-semibold mb-2">Gyakori Kérdések</h3>
            <p className="text-gray-600 mb-4">
              Válaszok a leggyakrabban felmerülő kérdésekre.
            </p>
            <a href="https://wpmarket.app/faq" className="text-blue-600 hover:text-blue-700 font-medium">
              GYIK megnyitása →
            </a>
          </div>
        </div>

        {/* Kapcsolatfelvételi űrlap */}
        <div className="mt-16 bg-white rounded-lg shadow-sm p-8">
          <h2 className="text-2xl font-bold mb-6">Kapcsolatfelvétel</h2>
          <form className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email cím
              </label>
              <input
                type="email"
                className="w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="email@example.com"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tárgy
              </label>
              <input
                type="text"
                className="w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Miben segíthetünk?"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Üzenet
              </label>
              <textarea
                className="w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows={4}
                placeholder="Írd le részletesen, miben segíthetünk..."
                required
              />
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
              >
                Üzenet küldése
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Support; 